.patient_pubnub_mgs
{
    list-style: none;
    width: 100%;
    height: calc(calc(100vh * .99) - 240px);
    border: 1px solid #ededed;
    overflow-y: auto;
    padding: 9px;
    margin-bottom: 12px;
}
.adminUser {
    BACKGROUND: #e1e1e1;
    margin-bottom: 10px;
    width: fit-content;
    padding: 10px;
    color: #000;
    border-radius: 0px 10px 10px 10px;
    font-size: 14px;
    display: block;
    word-break: break-word;
}
.currentUser {
    background: #57c7da;
    margin-bottom: 10px;
    padding: 10px;
    width: fit-content;
    font-size: 14px;
    border-radius: 10px 0px 10px 10px;
    color: #fff;
    margin-left: auto;
    display: block;
    word-break: break-word;
}

.chat_box{
    padding:12px;
}

.send_btn{
    font-size:14px;
}
@media screen and (max-width: 1024px) {
    .patient_pubnub_mgs{
        height: calc(calc(100vh * .99) - 298px);
    }
}
@media screen and (max-width: 992px) {
    .patient_pubnub_mgs{
        height: calc(calc(100vh * .99) - 350px);
    }
}

@media screen and (max-width: 757px) {
    .patient_pubnub_mgs{
        height: calc(calc(100vh * .99) - 360px);
    }
}