.Newsidebar-div ul {
    padding: 0px;
    margin: 0 auto;
    padding-top: 27px;
}

.Newsidebar-div ul li a {
    color: #646464;
    list-style-type: none;
    text-decoration: none;
}

.Newsidebar-div ul li a:hover {
    color: #57c7da;
}

.Newsidebar-div ul li {
    text-decoration: none;
    list-style-type: none;
    text-align: center;
    /* padding-top: 24px; */
    border-left: 1px solid transparent;
    margin-bottom: 25px;
    padding-bottom: 5px;
}

.navbar-light .navbar-nav .nav-link.active i {
    color: #33b0d0 !important;
    /* border-left:1px solid #57c7da !important;  */
}

.li.nav-item:active {
    border-left: 2px solid #57c7da !important;
}

.Newsidebar-div {
    float: left;
    width: 90px;
    border-right: 1px solid rgba(0, 0, 0, 0.13);
    min-height: 100vh;
    background: #FFFFFF;
    position: fixed;
    top: 70px;
    overflow: hidden;
    bottom: 0;
    left: 0;
    z-index: 999;
}

.newheader-span {
    display: block;
    font-size: 13px;
    padding-top: 3px;
}

.Newsidebar-div i {
    font-size: 18px;
    display: block;
}

@media (max-width:992px) {
    .Newsidebar-div {
        top: 70px;
        transform: translate3d(-100%, 0, 0);
        transition: all .2s ease-in;
    }
    .open .Newsidebar-div {
        transform: translate3d(0%, 0, 0);
    }
    /* .open .section
{
transform: translate3d(100px ,0,0);
transition: all .2s ease-in;
} */
    /* .open .mobile-header-btnn{
transform: translate3d(100px ,0,0);
transition: all .2s ease-in;
} */
}

li.nav-item .nav-linking.active {
    color: #33b0d0 !important;
}

li.nav-list:active {
    border-left: 3px solid #646464 !important;
    margin-left: 5px !important;
}

li.nav-li.nav-list:focus {
    border-left: 3px solid red !important;
}

@media (max-width:504px) {
    .Newsidebar-div {
        top: 70px;
        transform: translate3d(-100%, 0, 0);
        transition: all .2s ease-in;
        background: #FFFFFF;
    }
}

@media (max-width:992px) {
    .referralschatList.active {
        /* display: none; */
        background: #ffffff;
        margin: 0;
        position: unset;
        width: 100%;
        /* margin-bottom: 70px; */
        height: var(--mv__chatUser_allList) !important;
        overflow-x: auto !important;
        min-height: 77px !important;
        min-width: 100%;
        overflow: scroll;
    }
    .mv__tabs-content {
        margin-top: 0;
    }
    .mobile-view-chatuser {
        display: none;
    }
    .chatUser-allList .nav-item {
        width: auto;
        min-width: 120px;
    }
    .chatUser-list {
        width: 100%;
        overflow-x: auto;
        padding-bottom: 0;
    }
    ul.chatUser-allList {
        padding: 0px;
        margin: 0px;
        flex-wrap: nowrap;
    }
    .chartlist-inner-content h2 {
        padding: 12px 0px 0px 12px;
        font-size: 20px !important;
    }
    .chat-searcIcon .fa-search {
        left: 26px !important;
    }
    .chat-searcIcon {
        padding-bottom: 23px;
    }
    .chatList-inner {
        padding: 0 !important;
    }
    .chatList-inner h2 {
        text-align: left !important;
    }
    .chatUser-img {
        width: 40px;
        height: 40px;
    }
    .Page_content.refereal-actity-page {
        margin-left: 0px!important;
    }
    .mv__chatime {
        width: 100%;
        text-align: center;
    }
    ul.chatUser-allList li a {
        gap: 5px;
    }
}

li.nav-item .nav-linking.active {
    color: #33b0d0 !important;
    border-color: #33b0d0 !important;
}

.Newsidebar-div ul li {
    text-align: center;
    margin-bottom: 25px;
    padding: 0 3px;
}

.Newsidebar-div ul li a {
    color: #646464;
    list-style-type: none;
    text-decoration: none;
    border-left: 2px solid transparent;
    display: block;
}

.newheader-span {
    display: block;
    font-size: 11px;
    padding-top: 3px;
}