*{
    padding:0px;
    margin: 0px;
    font-family: 'Inter', serif;
}
p {
    margin: 0px;
}

.Patient_Journey h6{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #101828;
}
.top_section {
    align-items: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0px;
}
.justyfy{
    justify-content: space-between;
}

.top-button {
    text-align: end;
}
.cancle_button{
    padding: 9px 35px;
    background: #fff;
    border: 0;
    color: #969696;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid rgba(179, 179, 179, 0.9);
    box-shadow: 0px 4px 4px rgba(16, 24, 40, 0.03);
    border-radius: 5px;
}
.cancle_button:hover{
    background: #50a2b8;
    border: 1px solid #50a2b8;
}
.update_button {
    background: #48b0ce;
    padding: 9px 35px;
    border: 1px solid #48b0ce;
    border-radius: 5px;
    font-size: 14px;
}
.update_button:hover{
    background: #50a2b8;
    border: 1px solid #48b0ce;
}
.Patient_image {
    width: 82px !important;
    height: 55px !important;
    border-radius: 4px !important;
    overflow: hidden !important;
}
.Patient_image img{
    width: 100%;
} 
.Aidan_Nathan_card {
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    gap: 50px;
    border-bottom: 0.5px solid #D6D6D6;
    gap: 20px !important;
}

.Aidan_Nathan {
    line-height: 19px;
    font-size: 10px;
    color: #8099A6;
}
.Aidan_Nathan_inner {
    font-size: 13px;
}
.Aidan_Nathan_inner h6 {
    color: #263238;
    margin: 0;
}
.Cataract{
    color: #263238 !important;
}
.od p {
    background: #FFEBCD;
    border-radius: 5px;
    font-size: 12px;
    color: #D18819;
    padding: 1px 12px;
    border-radius: 20px;
}
.patient-big-active{
    background: #F9F9F9;
}
.Patient_journy_detail {
    margin-top: 30px;
}
.Patient_Contacted_inner{
    display: flex;
    gap: 40px;
    align-items: center;
}
.Patient_Contacted_card {
    border-radius: 10px;
    padding: 12px;
    padding-right: 28px;
}

.Radio_button{
    display: flex;
    gap: 20px;
    color: #000000;
    flex-wrap: wrap;
}
.message_file_comon_icon{
    display: flex;
    flex-wrap: wrap;
}
.message_text_color {
    color: #000000;
}
.discription {
    font-family: 'Helvetica';
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #3E3D3D;
}
.last_update {
    font-size: 12px;
    line-height: 22px;
    color: #8099A6;
}
.Surgery_Scheduled_card{
    padding: 12px;
}
.Last-updated-wrapper{
    padding:10px;
}
.Notes_last_update{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}
.Notes_discription{
    font-weight: 400;
    font-size: 12px;
    padding-top: 6px;
    line-height: 17px;
    color: rgba(62, 61, 61, 0.78);
}
.Notes_button {
    border: 0;
    gap: 20px;
}
a.add-notes-link {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FE5050;
}
button.nav-link.active.Notes_button_inner {
    background: #33B0CE;
    color: #fff;
    box-shadow: 0px 4px 10px rgb(51 176 206 / 12%);
    font-size: 12px;
    border-radius: 20px;
    width: 100px;
    height: 34px;
    border: 0;
}
button.nav-link.Notes_button_inner {
    background: #F6F6F6;
    border-radius: 40px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
}
button.nav-link.Document_button_inner {
    background: #F6F6F6;
    border-radius: 40px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
}
button.nav-link.active.Document_button_inner {
    background: #33B0CE;
    color: #fff;
    box-shadow: 0px 4px 10px rgb(51 176 206 / 12%);
    font-size: 12px;
    border-radius: 20px;
    width: 100px;
    height: 34px;
    border: 0;
}
nav.nav_button {
    display: flex;
    justify-content: space-between;
}
.add_notes_btn {
    background: 0 0;
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FE5050;
}

.fancyList ul {
    list-style: none;
}
.fancyList ul li {
    position: relative;
}
.fancyList ul li:before {
    position: absolute;
    content: "";
    left: -14px;
    top: 19px;
    bottom: -5px;
    border: 2px dashed #E2E2E2;
}
.fancyList ul li:after {
    position: absolute;
    content: "";
    left: -20px;
    top: 13px;
    width: 15px;
    height: 15px;
    background: #FE5050;
    border-radius: 100%;
}

ul.fancyList-left {
    list-style: none;
}
ul.fancyList-left li {
    position: relative;
}
/* ul.fancyList-left  li:before {
    position: absolute;
    content: "";
    left: -14px;
    top: 19px;
    bottom: -5px;
    border: 2px dashed #E2E2E2;
} */
ul.fancyList-left  li:after {
    position: absolute;
    content: "";
    left: -20px;
    top: 13px;
    width: 15px;
    height: 15px;
    background: rgba(254, 80, 80, 0.15);
    border-radius: 100%;
}

/* new css */
.journey_notes_list ul li{
    font-size:14px;
}

.journey_notes_list ul {
   list-style-type: decimal;
}

.readmore__content p { 
    display: -webkit-box;
    font-size: 14px;
    line-height: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.2s all;
}
.readmore__content.show_more p { 
    transition: 0.2s all;
    -webkit-line-clamp: 200;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.journey_notes{
    overflow-y: scroll;
    height: 312px;
}


/* .readmore__content p{
    float:left;
} */

/* .readmore__content p{
    float:left;
} */

.journey_notes h6{
    text-align:left;
}

@media (max-width:576px){
    .Aidan_Nathan {
        padding: 7px 0px;
    }
    .Aidan_Nathan_card{
        display: block;
    }
    .Patient_image {
        width: 100% !important;
        height: auto !important;
    }
    .od {
        width: 100px;
    }

}
