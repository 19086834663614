
.patient-cards-lists li{
    list-style-type:none !important;
}

.recent-document_div {
    overflow: hidden;
    overflow-y: auto;
    height: auto;
    max-height: 250px;
}

.journey-list-li{
    list-style-type:none !important;
    margin:0 auto;
}

.journey-detail-div{
    display:flex !important;
}

.journey-img-holder{
    margin:0 auto;
}

.icon{
    padding-right:3px;
}
.journey-icon{
    padding-right:16px;
}

.float-left{
    text-align:left !important;
}

 .btnn-savee {
    background: #48b0ce !important;
    color: #fff !important;
    padding: 4px 13px !important;
}


.nav-h2{
    color: #263238;
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    padding-top:25px;
}


 .file-upload-btn{
    position:absolute;
    bottom:0;
    width:100%;
    background:#FFFFFF;
 }

 

 .offcanvas-body {
    padding-bottom: 140px !important;
}

.offcanvas-backdrop.show {
    opacity: .1 !important;
}

.journey-div{
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 200px;
    height: auto;
}

.noted-div{
    overflow:hidden;
    overflow-y:auto;
    height: auto;
    /* max-height:200px; */
    text-align:left;
    font-size:14px;
    padding: 0px 14px 0px 0px;
    border-radius:12px;
}

.noted-card{
    border: 1px solid rgb(241 237 237);
    padding: 7px;
    border-radius: 12px;
    margin-bottom:12px;
}
.noted-card p{
    margin:0;
    padding-bottom:6px;
}

.noted-card h5{
    color:#3E3D3D;
    font-size:13px;
    font-weight:500;
    margin:0;
}

.offcanvas-title h5{
    padding-top:15px !important;
}
li.mv__recent_document {
    /* max-width: 130px;  */
    width: calc(33.3% - 10px) !important;
    min-height: 117px;
    height: 90px;
    padding: 0;
    margin-bottom: 10px;
    margin-right: 10px;
}
.mv__recent_document-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0px !important;
    border: 1px solid #f3f3f3;
    padding:2px;
}

.mv__recent_document-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.mv__journey_box {
    padding: 0;
}

.mv__journey_list {
    /* max-width: 130px;  */
    width: calc(33.3% - 10px) !important;
    min-height: 117px;
    height: 90px;
    padding: 0;
    margin-bottom: 10px;
    margin-right: 10px;
}
.mv__journey_list .journey-img-holder{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0px !important;
    border: 1px solid #f3f3f3;
    padding:2px;
    position: relative;
}
.mv__journey_list .journey-img-holder img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.mv__journey_list .journey-img-holder .close-btn {
    position: absolute;
    top: 0px;
    right: 2px;
    z-index: 1;
    cursor: pointer;
}