/*Profile Modal css */

.avatar-upload {
    position: relative;
    max-width: 152px;
    margin: 20px auto;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
    content: "\f040";
    font-family: "FontAwesome";
    color: #757575;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.avatar-upload .avatar-preview {
    width: 142px;
    height: 142px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

input.nameInput::placeholder {
    font-size: 14px;
    font-weight: 100;
    opacity: .7;
}

.avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.imG-previewMv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}

.border-top-1 {
    border-top: 1px solid #ededed;
}

button.mv_Save.btn.btn-primary {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    text-align: center;
    margin: 20px 0;
    margin-bottom: 0;
    font-size: 14px;
    margin-left: auto;
    border: 0;
    background: #57c7da !important;
}

.profile-modal-head {
    background: #e9e9e9;
    padding: 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-weight: 600;
    font-size: 16px;
}

.profile-modal-body {
    padding: 15px;
}

.labelInput {
    font-size: 14px;
    font-weight: 500;
}

input.nameInput {
    /* background: #e9e9e9; */
    border: 1px solid#e9e9e9;
    height: 42px;
    /* margin-left: 20px; */
    padding: 10px 20px;
    width: 100%;
    font-size: 15px;
    color: #000;
}

.error {
    font-size: 12px;
    margin-top: 5px;
    color: red;
}

input.nameInput:hover {
    background: transparent;
}

.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: -35px;
    position: absolute;
    top: 0;
    width: 28px;
}

.avatar-preview img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

h6.IconGreen-labelText {
    margin-top: -20px !important;
}

@media (max-width: 992px) {
    .imG-previewMv {
        /* display: flex;
      align-items: center; */
        flex-wrap: wrap;
        /* justify-content: center; */
        gap: 30px;
    }
}