* {
    padding: 0px;
    margin: 0px;
    font-family: 'Inter', serif;
}

p {
    margin: 0px;
}

.main_heading h4 {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    color: #101828;
}

.top_heading {
    margin-bottom: 20px;
}

.report_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.Document_text h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #101828;
}

.mv__bothsec {
    display: flex;
    justify-content: start;
    gap: 50px;
    align-items: center;
}

.searchicon_main {
    position: relative;
}

::placeholder {
    color: #969696 !important;
}

.searchicon_main input {
    padding: 9px 16px;
    border-radius: 4px;
    padding-left: 40px;
    font-size: 13px;
    color: #969696;
}

.searchicon_main i {
    position: absolute;
    top: 9px;
    left: 15px;
}

.Surgery-Scheduled {
    font-size: 12px;
    width: 165px;
    margin-top: 9px;
    padding: 6px 11px;
}

.recent_document_text {
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: #101828;
}

.view_all_btn {
    text-align: end;
}

.view_all_btn a {
    text-decoration: none;
    color: #33B0CE;
    text-align: end;
    font-weight: 700;
    font-size: 15px;
    line-height: 36px;
}

.top_section-border {
    border-bottom: unset !important;
}

.tab-My_table .table_heading {
    background: transparent;
}

.My_table thead tr th {
    min-width: 150px;
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0.25rem;
    color: #000;
}

button#nav-profile-tab {
    background: #F8F8F8;
    border-radius: 5px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    color: #BFCACF;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: #33B0CE !important;
    color: #ffff !important;
}

.document_slip {
    font-size: 10px;
    color: #8099A6;
    background: #ffff;
    padding: 10px;
    border-bottom-left-radius: 7.6569px;
    border-bottom-right-radius: 7.6569px;
}

.slip_img {
    width: 180px;
    margin: 0 auto;
}

.slip_img img {
    width: 100%;
}

.slip_date {
    position: absolute;
    top: 6px;
    color: #ffff;
    right: 6px;
    padding: 5px;
    background: #FE5050;
    border-radius: 3px;
}

.slip_date h6 {
    font-size: 10px;
    margin: 0;
}

.color_grey {
    color: #8099A6;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 2px;
}

.color_black {
    color: #8099A6;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 6px;
}

.Recents_Documents_slip {
    background: #F6F6F6;
    box-shadow: 0px 3.06276px 12.251px rgb(0 0 0 / 6%);
    border-radius: 7.6569px;
    position: relative;
}

.attached_button {
    color: #33B0CE;
    border: 0.5px solid #33B0CE;
    border-radius: 3px;
    background: #ffff;
    font-size: 9px;
    padding: 2px 15px;
    margin-top: 5px;
}

.Recents_Documents_slip a:after {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
    content: '\f06e';
    width: 50px;
    height: 50px;
    font-family: FontAwesome;
    display: inline-block;
    font-size: 20px;
    /* border: 1px solid #ffffff; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    line-height: 50px;
    z-index: 9;
    background: #ffffff;
    color: #33B0CE;
    text-align: center;
    border-radius: 50px;
}

.Recents_Documents_slip a:before {
    opacity: 1;
    content: '';
    width: 100%;
    height: 100%;
    display: inline-block;
    font-family: FontAwesome;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 25px;
    line-height: 25px;
    z-index: 1;
}

.Recents_Documents_slip a:hover:after {
    opacity: 1;
}

.gallery a:hover:before {
    background-color: rgba(51, 176, 206, 0.49);
}

.gallery a:after,
.gallery a:hover:after {
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    -moz-transition: all 1s;
}

.table {
    font-size: 13px;
    line-height: 3.1;
    margin-bottom: 0;
    margin-top: 15px;
    font-weight: 500;
    font-size: 13px;
}

table.table.My_table {
    color: #455A64;
}

tr.table_heading th {
    border: 0px !important;
}


/* .My_table thead tr th {
    min-width: 150px;
} */

table thead tr.table_heading {
    border-radius: 6px;
    border: 0px !important;
    color: #8495B1;
}

.report_img {
    /* background: url(report.png); */
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    border: 1.5px solid #8099A6;
    border-radius: 9px;
}

.report_img img {
    width: 100%;
}

tr.table_heading th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

tr.table_heading th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.table_inner {
    display: flex;
}

.table_icon {
    padding-left: 10px;
}

.pagination_wrapper {
    padding-top: 50px;
}

.pagination_card {
    justify-content: end;
}

.pagination_card li a {
    color: #8099A6;
}

.page-item.active .page-link {
    z-index: 3;
    color: #33B0CE;
    background-color: #ffffff;
    border-color: #dfe2e6;
}

a.page-link.link_next {
    color: #33B0CE;
}

.page-top-insights {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.page-top-insights .insight-box {
    /* padding: 15px; */
    width: 100%;
    max-width: 294px;
}

.page-top-insights .insight-box p {
    font-weight: 600;
    margin-bottom: 10px;
}

.page-top-insights .insight-box .text_232 {
    margin-bottom: 0;
}


/**** Ranjeet css ****/


/* .My_table.mv__activity_list{
    margin-top: 45px;
} */

.mv__activity_list tbody tr td {
    border-top: 0;
}

.mv__activity_wrapper {
    position: fixed;
    top: calc(var(--header_height));
    width: auto;
    height: var(--activity_wrapper);
    background: white;
    z-index: 99;
    padding: 20px 20px 22px !important;
}

.Patient_journy_detail .activity_card {
    margin-left: 0px !important;
}

.message_file_comon_icon .mv__link_text,
.message_file_comon_icon .mv__link_text:focus,
.message_file_comon_icon .mv__link_text:hover {
    color: #000000;
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: normal;
}


/* .mv_tab_content {
    margin-top: 30px !important;
} */


/* ::-webkit-scrollbar {
    width: 5px;
  } */


/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #D2EBF1;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #c8eef8;
}

body.modal-open>html {
    overflow: hidden;
}

.mv__patient_box {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    position: relative;
}

.mv__patient_img_holder {
    width: 100%;
    height: 100%;
}

.mv__trash_icon {
    font-size: 10px;
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 100%;
    border: 1px solid #57c7da;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

.mv__norecord .record_nfound {
    min-height: 500px;
    height: auto;
}

.dropdown-toggle:after {
    background-image: url(../img/mv__arrowBottom.svg) !important;
    width: 20px;
    height: 10px;
    background-repeat: no-repeat !important;
    margin-left: 0px !important;
    background: transparent;
    color: transparent;
    background-size: contain;
}

.mv__Page_content {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.mv__Page_content .mv_tab_content {
    padding: 0px 40px !important;
}

.mv__chatUser-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #646464;
    color: #ffffff;
}

.mv__Patient_img {
    background: #646464;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.mv_fisrtleter {
    margin: 0;
}

.info-card {
    background: whitesmoke;
    padding: 15px;
    border-radius: 10px;
}

.info-card h5 {
    font-size: 12px !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #58b3c9;
}

.info-card h4 {
    font-size: 18px !important;
}

#pills-doc-info h5 {
    font-size: 15px;
    opacity: .6;
}

@media (max-width:1300px) {
    .mv__bothsec {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
}

@media (max-width:992px) {
    .mv__activity_wrapper {
        top: calc(var(--header_height) + var(--mv__chatUser_allList));
        left: 0;
    }
    .Page_content.refereal-actity-page.mv__Page_content {
        padding: 0px !important;
    }
    /* .mv__mobile_header_btn {
        margin-top: 160px !important;
    } */
    /* .mv_tab_content {
        margin-top: -80px !important;
    } */
    .mv__referralschatList {
        height: auto;
        overflow: hidden !important;
        overflow-x: auto;
    }
    .mv__Page_content .mv_tab_content {
        margin-top: calc(var(--header_height) + var(--activity_wrapper) + var(--mv__chatUser_allList));
        padding-top: 0 !important;
    }
    /* .top_section {
        margin-bottom: 20px;
    } */
    .mv__chatime {
        display: none;
    }
    .mv__Page_content .mv_tab_content {
        padding: 0px 0px !important;
    }
    .Dr_Josaph {
        font-size: 15px;
    }
    .mv__activity_wrapper {
        padding: 10px !important;
    }
    .home-page-section,
    .mv_main__sec {
        margin-top: 0;
        margin-left: 0;
    }
}

@media (max-width:767px) {
    /* .mv__mobile_header_btn {
        margin-top: 220px !important;
    } */
    .mv__Page_content .mv_tab_content {
        padding: 0px 0px !important;
    }
}

@media (max-width:575px) {
    /* .mv__mobile_header_btn {
        margin-top: 230px !important;
    } */
    .top_section {
        margin-bottom: 10px;
    }
}