.spinnerDiv {
  background-color: white;

  /* height: 100vh; */

  opacity: 0.88888;
}

.login-loader {
  position: absolute;
}

.login-loader {
  position: fixed;

  background: #ffffff;

  top: 0;

  right: 0;

  bottom: 0;

  left: 0;

  z-index:100;

  display: flex;

  align-items: center;

  justify-content: center;
}

.spinner-loader {
  position: absolute;

  top: 48%;

  width: 55px;

  height: 55px;
}
