*{
    padding: 0px;
    margin:0px;
}
.Login_section {
    background: linear-gradient(170.63deg, rgba(51, 186, 211, 0.2) -74.96%, rgba(255, 255, 255, 0) 43.17%), #FFFFFF;
    height: 100vh;
    font-family: 'Inter', serif;
    position:relative;
}
.Login_section::before{
    content: "";
    width: 300px;
    position: absolute;
    background: rgba(247, 247, 247, 0.21);
    height: 300px;
    border-radius: 100%;
    right: -48px;
    top: -130px;
}

.main_row{
    padding-top: 150px;
}
.left_side {
    padding-right: 100px;
}
.left_text{
    padding-bottom: 20px;
}
.left_text h2{
    font-size: 24px;
}
.left_text p {
    color: rgba(62, 61, 61, 0.52);
    font-size: 14px;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ebebeb;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255);
}
.email_text {
    color: rgba(62, 61, 61, 0.52);
    font-size: 13px;
}
.password_text {
    color: rgba(62, 61, 61, 0.52);
    font-size: 13px;
}
.eyeicon {
    position: relative;
}
.mv__password_eye {
    width: 25px;
    height: 25px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #33B0CE;
}
 .eyeicon i{
    position: absolute;
    top: 38px;
    right: 5px;
}
.eyeicon .form-control {
    padding-right: 30px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.Login_button {
    width: 100%;
    margin-top: 34px;
    padding: 10px;
    background: #33B0CE !important;
    height:50px;
    border:none !important;
}
.Login_button:focus, .Login_button:hover {
    background: #2195b1;
  
}
.Forgot_password {
    text-align: center;
    padding-top: 20px;
}
.Forgot_password a{
    text-decoration: none;
    color: #000;
}
.right_side {
    padding-left: 100px;
}
.right_side h2 {
    font-size: 24px;
    text-align: center;
}
.right_side p{
    color: rgba(62, 61, 61, 0.52);
    text-align: center;
    font-size: 14px;
}
.main_img{
    width: 385px;
    padding-top: 25px;
}
.main_img img{
    width: 100%;
}
@media (max-width:576px){
    .left_side{
        padding: 0px;
    }
    .right_side {
        padding-left: 0px;
        display: none;
    }

}
@media (max-width:768px){
    .left_side{
        padding: 0px;
    }
    .right_side {
        padding-left: 0px;
    }

}
@media (max-width:991px){
    .left_side{
        padding: 0px;
    }
    .right_side {
        padding-left: 0px;
    }
    .main_img {
         width:auto;
        padding-top: 25px;
    }
    

}
