  /* updates css start */
  .NewHeader-nav {
  background: #57c7da;
  padding: 0px !important;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  top: 0;
  height: 70px;
  }
  /* updates css end */
  .Newheader-input {
  width: 500px !important;
  background: #d7f2f6;
  border: none !important;
  }
  .newheader-btnn {
  border-radius: 9px;
  margin-right: 23px;
  }
  .header-logo {
  padding-right: 16px;
  }
  .NewHeader_logo {
  width: 178px;
  }
  .mobile-view-btnn {
  border: 1px solid #33b0ce;
  border-radius: 10px;
  margin-left: 12px;
  min-height: 37px;
  line-height: 3;
  padding: 0px 12px 0px 12px !important;
  }
  .newHeader-post-patient {
  border: 1px solid #ffffff;
  color: #ffffff !important;
  }
  /* a.nav-link.active {
  background: #ffffff !important;
  color: #33b0ce !important;
  } */
  .Newheader-content {
  display: flex;
  align-items: center;
  }
  .Newheader-ul {
  align-items: center;
  }
  .Newheader-content .fa-chevron-down {
  padding: 0px 23px 0px 4px;
  color: #ffffff;
  }
  .Newheader-navbar-items {
  padding-left: 27px;
  }
  .mobileview-nav-btnn {
  display: block;
  }
  .mobile-btn {
  background: #33b0ce !important;
  color: #ffffff !important;
  }
  .mobile-design-btnn {
  display: none;
  }
  .mobileview-ul {
  list-style-type: none;
  display: flex;
  }
  a.mobile-btn.active {
  background: #33b0ce !important;
  color: #ffffff !important;
  }
  .mobile-view-btnn {
  border: 1px solid #33b0ce;
  border-radius: 10px;
  margin-left: 12px;
  }
  .mobileview-ul {
  padding: 0;
  }
  .mobile-view-search-icon {
  display: none;
  }
  .Page_content {
  padding-top: 101px;
  }
  /*** Ranjeet css ****/
  .mv__newheader_ul .mv__img_holder {
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: #ffffff;
  padding: 0;
  }
  .mv__newheader_ul .mv__img_holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  }
  .mv__newheader_ul .dropdown button {
  padding: 0;
  }
  .mv__newheader_ul .dropdown button a.nav-link {
  padding: 0;
  }
  .mv__newheader_ul .dropdown button:not(:last-child) {
  margin-right: 0px;
  }
  .mv__navbar_toggler_icon {
  background-image: url(../img/mv__mobile_menu.svg) !important;
  }
  .search_div {
  position: relative;
  }
  .search_h3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .mv__search_field .Newheader-input {
  padding-right: 35px;
  border-radius: 10px;
  }
  .mv__user_text {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #57c7da;
  color: #ffffff;
  border-radius: 100%;
  border-radius: 1px solid #ccc;
  }
  .mobileview-nav-btnn .mv__btn__refer, .mobileview-nav-btnn .mv__btn__refer:hover, .mobileview-nav-btnn .mv__btn__refer:focus {
  background: #ffffff !important;
  color: #57c7da !important;
  transition: 0.2s all;
  }

  .search_content_div {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  }
  .search_right_div {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  }
  @media (max-width: 683px) {
  .mobile-view-search-icon {
  display: block;
  font-size: 16px;
  color: #ffffff;
  /* padding-right:20px; */
  }
  .search_div {
  display: none;
  }
  }
  @media (max-width: 445px) {
  .mobileview-ul {
  flex-direction: column;
  }
  }
  @media (min-width: 990px) and (max-width: 1278px) {
  .Newheader-input {
  width: 300px !important;
  }
  }
  @media (min-width: 990px) and (max-width: 1069px) {
  a.nav-link.active {
  font-size: 14px !important;
  }
  }
  @media (min-width: 990px) and (max-width: 1090px) {
  a.nav-link.active {
  font-size: 13px !important;
  }
  .newHeader-post-patient {
  font-size: 13px;
  }
  }
  @media (max-width: 992px) {
  .mobileview-nav-btnn {
  display: none;
  }
  .mobile-view-btnn{
    margin-left: 0;
  }
  .mobile-design-btnn {
  display: block;
  text-align: center;
  }
  .mv__mobile_header_btn.mv__btn_top{
  margin-top: calc(var(--header_height) + var(--activity_wrapper)) !important;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 10px;
  display: none;
  }
  .mv__referralschatList {
  position: fixed !important;
  top: var(--header_height) !important;
  left: 0;
  width: 100% !important;
  z-index: 99;
  }
  .mv__mobile_header_btn {
  margin-top: calc(var(--header_height)) !important;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 10px;
  }
  /* .report_image {
  margin-bottom: 14px !important;
  } */
  .Page_content {
  /* padding-top: 101px !important; */
  margin-left: 0px !important;
  padding: 40px;
  }
  .Newheader-input {
  width: 300px !important;
  }
  .home-page-section {
  margin: 0px !important;
  padding: 0px !important;
  }
  .chatList-inner {
  display: none;
  }
  /* .mv__newheader_ul .dropdown button {
  margin-right: 15px;
  } */
  
  }
  @media (max-width: 683px) {
  .Newheader-input {
  display: none !important;
  }
  .mv__fa_search{
  display: none;
  }
  .mobile-view-searchbar {
  display: block !important;
  }
  }
  @media (max-width: 416px) {
  .NewHeader_logo {
  width: 140px !important;
  padding-right: 0px;
  }
  .header-logo {
  padding-right: 0px !important;
  }
  }
  @media (max-width: 485px) {
  .mobile-view-btnn {
  font-size: 14px !important;
  margin-left: 0px !important;
  padding: 0 !important;
  }
  .NewHeader_logo {
  width: 160px;
  }
  }
  /* notification page */
  .notification_page_ui,
  .document-page-ui {
  padding-top: 71px !important;
  }
  /* .existing-page,
  .new-patient-page {
  padding-top: 71px !important;
  padding-left: 137px !important;
  } */
  .navbar-toggler {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: none !important;
  }
  .Newheader-img-holder {
  padding: 4px 0px 4px 0px;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  }
  .dropdown-menu.show {
  margin-top: 11px !important;
  }
  a.nav-link.newheader-btnn.newHeader-post-patient {
  font-size: 14px !important;
  }
  .search_h3 {
  font-size: 15px;
  text-align: center;
  padding-top: 12px;
  }
  .search_div {
  width: calc(100% - 8px) !important;
  }
  @media (max-width: 504px) {
  .Newheader-img-holder {
  width: 30px !important;
  height: 30px !important;
  margin-right: 0px;
  }
  }
  @media (max-width: 1080px) {
  .NewHeader_logo {
  width: 160px;
  }
  }

  @media (max-width:575px){
  .mv__activity_wrapper .nav-item .nav-link {
  font-size: 14px;
  padding: 10px;
  }
  .mv__navbar_toggler_icon {
  width: 22px !important;
  }
  .NewHeader-nav .navbar-toggler {
  padding: 0px 10px;
  }
  }
  @media (max-width: 683px){
  .Newheader-input 
  {
  display: none;
  position: fixed;
  top:16px;
  left:10px;
  width: 100%;
  }
  }