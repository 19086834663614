*{
    padding:0px;
    margin: 0px;
    font-family: 'Inter', serif;
}
p {
    margin: 0px;
}
.outdoor_dr_text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #101828;
}
.Top_searchicon {
    position: relative;
    max-width: 224px;
    margin-left: auto;
}
.Top_searchicon input {
    padding: 7px 16px;
    padding-left: 40px;
    font-size: 13px;
}
.Top_searchicon i {
    position: absolute;
    top: 9px;
    left: 15px;
}
.flex_wrapper {
    display: flex;
    width: 187px;
    gap: 10px;
}
span.reports-month {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #33B0CE;
    border-radius: 6px;
    padding: 4px;
    background: #d3f2fa;
}
span.Full_Report_text {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #FE5050;
    border-radius: 6px;
    padding: 4px;
    background: #ffc7c7;
}
.My-table a {
    text-decoration: none;
}

.My-table thead tr th {
    min-width: 150px;
}