.record_error {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* z-index:999999; */
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    transition: background-color 300ms;
    justify-content: center;
}

.record_error_content {
    display: flex;
}

.record_error-img {
    width: 57px;
    height: 57px;
}

.record_error-img img {
    width: 100%;
    height: 100%;
}

.record_error_heading h4 {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
}

.record_error_heading {
    padding-left: 12px;
}


/****NEW CSS**/

.record_nfound {
    width: 100%;
    height: 100vh;
    position: relative;
}

.record_nfound-content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.record_nfound-image {
    width: 210px;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
}

.record_nfound-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.record_nfound-content h1 {
    font-size: var(--font_size_130);
    margin: 0px;
    font-weight: 800;
    font-family: var(--primary_font_family);
    line-height: normal;
    text-align: center;
    background: linear-gradient(100.89deg, var(--gradient_primary_color) 19.35%, var(--gradient_secondry_color) 87.38%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.record_nfound-content h4 {
    font-size: var(--font_size_24);
    font-weight: 800;
    font-family: var(--primary_font_family);
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary_color);
    letter-spacing: 1px;
    margin: 0px;
    margin-bottom: 15px;
}

.record_nfound-content p {
    font-size: var(--font_size_16);
    margin: 0px;
    font-weight: 400;
    font-family: var(--primary_font_family);
    line-height: normal;
    color: var(--primary_color);
    text-align: center;
    letter-spacing: 1px;
}

@media(max-width:575px) {
    .record_nfound-image {
        width: 300px;
    }
    .record_nfound-content h1 {
        font-size: var(--font_size_70);
    }
    .record_nfound-content h4 {
        font-size: var(--font_size_18);
    }
    .record_nfound-content p {
        font-size: var(--font_size_14);
    }
}