*{
    padding:0px;
    margin: 0px;
    font-family: 'Inter', serif;
}
p {
    margin: 0px;
}
.Page_content {
    background: #fff;
    margin-left: 0 !important;
    min-height: 100vh;
    padding: 12px 40px;
}

.home-page-section, .mv_main__sec {
    margin-top:var(--header_height);
    margin-left:var(--sidebar);
}
/* .Patient_img {
    width: 40px;
    border-radius: 50px;
    overflow: hidden;
} */
.Patient_img img{
    width: 100%;
}
.mv__notes .mv__notess {
    font-size: 16px;
    font-weight: 400;
    color: #212529;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: calc(100% - 100px);
    float: left;
}
.mv__notes .mv__moreText {
    color: #57c7da;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.main_heading h6 {
    font-size: 20px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 30px;
}
.Aidan_Nathan{
    color: #263238 ;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 2px;
}
.nav-link {
    color: #000;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: #33B0CE !important;
    color: #ffff !important;
    box-shadow: 0px 4px 14px rgb(51 176 206 / 29%);
    border-radius: 6px;
}
.Aidan_wrapper {
    /* display: flex; */
    padding: 0px 20px 22px;
    gap: 60px;
    border-bottom: 0.5px solid #D6D6D6;
}
.Patient_img {
    width: 40px;
}
ul#pills-tab {
    gap: 18px;
}
.searchicon_main {
    position: relative;
}
::placeholder {
    color: #969696 !important;
}
.searchicon_main input {
    padding: 9px 16px;
    border-radius: 4px;
    padding-left: 40px;
    font-size: 14px;
    color: #969696;
}
.searchicon_main i {
    position: absolute;
    top: 9px;
    left: 15px;
}
.Surgery_Scheduled {
    font-size: 14px !important;
    width: 165px;
    margin-top: 9px;
    padding: 6px 11px;
}

.recent_document_text{
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: #101828;
}
.view_all_btn {
    text-align: end;
}
.view_all_btn a{
    text-decoration: none;
    color: #33B0CE;
    text-align: end;
    font-weight: 700;
    font-size: 15px;
    line-height: 36px;

}
.My_table thead tr th {
    min-width: 150px;
}


button#nav-profile-tab {
    background: #F8F8F8;
    border-radius: 5px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    color: #BFCACF;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: #33B0CE !important;
    color: #ffff !important;
}
.top_section .form-select, .Surgery-Scheduled {
    min-width: 140px;
    height: 35px;
    font-size: 14px !important;
}
.top_section {
    display: flex;
    justify-content: left;
    padding-bottom: 0;
    flex-wrap: nowrap;
}
.mv__top_section {
    justify-content: start;
}
.report_img {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    border: 2px solid #8099A6;
    border-radius: 9px;
    overflow: hidden;
}
.report_img img{
    width: 100%;
}
.Dr_Josaph{
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #646464;

}
.mv__update_details {
    background: rgb(87, 199, 218);
    width: 200px;
    padding: 10px 15px;
    border-radius: 7px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin: 20px auto;
    display: block;
}
.mv__new-patient-page form .form-control {
    height: 40px;
    width:100%;
}
.Dr_Josaph span{
    font-weight: 600;

}
.nav-pills .nav-link:hover{
    color: #000000 !important;
}
.nav-pills .nav-link.active:hover{
    color: #ffffff !important;
}
.Aidan_Nathan {
    line-height: 19px;
    font-size: 10px;
    color: #8099A6;
}
.Aidan_Nathan_inner {
    font-size: 13px;
}
.Aidan_Nathan_inner h6 {
    color: #263238;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.Cataract_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1B8098;
    /* margin-top: 1px; */
    margin:0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

table thead tr.table_heading {
    border-radius: 6px;
    border: 0px !important;
    background: transparent;
    color: #8495B1;
}
.attach_btn {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    background: transparent;
    color: #33B0CE;
    border: 1px solid #33B0CE;
    border-radius: 6px;
    padding: 5px 14px;
}
.table_icon {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
    padding-top:6px;
    
}
.table{
    line-height: 3.1;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
}
/* .My_table {
    margin-top: 30px;
} */

.My_table thead tr th {
    min-width: 150px;
}
tr.table_heading td {
    padding-left: 24px;
}
/* tr.table-data td {
    padding-left: 24px;
} */
.table_inner{
    display: flex;
}
.patient_img {
    padding-right: 13px;
}
.My_table thead tr th {
    min-width: 150px;
}


.Patient_journy_detail {
    margin-top: 0px !important;
}
.activity_card {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,.07);
    flex-grow: 1;
    margin-left: 60px;
    margin-bottom: 0px;
    padding: 22px 15px;
    border-radius: 0;
}
.activity_card ul {
    margin-left: 0;
    padding-left: 0;
    position: relative;
    list-style: none;
    margin-bottom: 0;
}
.activity_card ul:before {
    position: absolute;
    left: -45px;
    top: -2px;
    background:#1AD52C;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    content: "";
    visibility: hidden;
}

.activity_card .checkedmark:before {
    /* background: url(../images/checkedmark-icon) no-repeat scroll center / cover; */
    height: 24px;
    width: 24px;
}

.Contacted_inner {
    display: flex;
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
}
.Contacted_card {
    border-radius: 10px;
    padding: 0px;
    padding-right: 28px;
    padding-left: 0!important;
}

.Contacted_card h6{
    padding-bottom:18px;
    margin:0;
}

.Radio_button {
    display: flex;
    gap: 20px;
    color: #000000;
    position: relative;
    z-index: 90;
}
.message_file_comon_icon{
    display: flex;
}
.message_file_comon_icon_disabledButton{
    /* display: flex; */
    pointer-events: none;
    opacity: 0.4;
}
.chat_text_color {
    color: #000000;
}
.discription {
    font-family: 'Helvetica';
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #3E3D3D;
    margin-top: 6px;
}
.Request_btn{
    text-align: end;
    padding-top: 45px;
    width: 30%;
}
.Request_btn button {
    background: 0;
    color: #33B0CE;
    border: 1px solid #33B0CE;
    border-radius: 10px;
    font-size: 13px;
}
.Request_btn button:hover{
    background: #1B8098;
    border: 1px solid #1B8098;
}


input[type="radio"] {
    position:relative;
    margin-bottom:10px;
   }
   
   input[type="radio"]:before {
    position: absolute;
    content: "";
    left: 0px;
    top: -2px;
    right: 0px;
    bottom: 0px;
    height: 17px;
    border-radius: 100%;
    width: 17px;
    background: #fff;
    border: 1px solid #E0E0E0;
}
input[type="radio"]:checked:after {
    position: absolute;
    background: #33B0CE;
    content: "";
    left: -1px;
    right: 0;
    top: -1px;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px #33b0ce;
}
   .form__radio-group label,.Radio_button label {
    padding-left: 7px;
}




/* .refereal-actity-page {
    margin-left: 468px !important;
} */
input:focus, textarea:focus {
    box-shadow: unset !important;
    border-color: #bbbbbb!important;
}

@media (max-width: 1024px){
/* .Page_content.refereal-actity-page {
    margin-left: 324px!important;
} */
.mv__bothsec {
    flex-direction: column;
}
}


@media (max-width:992px){
   
    .Page_content{
        padding-top:10px !important;
       } 
   
    .Page_content {
        margin-left: 0px;
        padding: 40px;
    }
    .activity_card {
        display: block;
    }
    .Request_btn {
        text-align: left;
        padding-top: 0px;
        width: 100%;
    }
    .home-page-section, .mv_main__sec {
        margin-top: 0;
        margin-left: 0;
    }
     /* .Page_content.refereal-actity-page{
        margin-left:0px !important;
    } */
  

}
@media (max-width:768px){
    .sidebar_section{
        display: none;
    }
    .top_section {
        display: flex;
        /* margin-bottom: 10px; */
        gap: 10px;
    }
    .Aidan_wrapper {
        display: block;
        padding: 20px;
    }
    .slip_img {
        width: 100%;
        margin: 0 auto;
    }
    tr.table-data td {
        padding-left: 10px;
    }
    .Page_content {
        margin-left: 0px;
        padding: 10px;
    }
    .Total_Patients {
        margin-bottom: 15px;
    }
    .Recents_Documents_slip {
        margin-bottom: 15px;
    }
    .mv_nav__pills {
        justify-content: left;
    }
  
}
@media (max-width:575px){
    .Page_content {
        margin-left: 0px;
        padding: 10px;
    }
    .Total_Patients {
        margin-bottom: 15px;       
    }
    .Total_ODS {
        margin-bottom: 15px;
    }

    .activity_card{
        margin-left:45px;
        padding-left:0px ;
    }
 
    /* .container-fluid.mobile-header-btnn {
        margin-top: 215px !important;
    } */
   
}

.btn-bck{
    background:#57c7da !important;
    border-radius:2px !important;
    color:#FFFFFF !important;
}