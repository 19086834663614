.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: none !important;
}

.dropdown-menu .show {
  top: 12px !important;
}
.dropdown-menu.show {
  top: 8px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  border: none;
}

.dropdown-content-items {

  font-weight: 600;
}


a.dropdown-content-items.dropdown-item:active {
  background: none;
  color: #000000;
}