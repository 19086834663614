.toast {
    width: 100%;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: #bffba9d9;
    background-clip: padding-box;
    border-radius: 0.25rem;
    margin: 0 auto;
    text-align: center;
    color: #305921d9;
    font-weight: 500;
    box-shadow:none !important;
}