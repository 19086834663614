
.top-section {
    align-items: center;
    /* border-bottom: 0.5px solid rgba(0, 0, 0, 0.1); */
    padding-bottom: 20px;
}
.btn-save{
    background: #48b0ce !important;
    color: #fff !important;
    padding: 8px 29px !important;
}
.btn-save:hover{
    background: #5eadc3;
    color: #fff;
    padding: 8px 29px;
}
.spacing-row{
    margin-bottom: 14px;
}
.flex-wrapper{
    display: flex;
}
.form{
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px;
}
.document-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
}
.et_pb_contact_form_label {
    display: block;
    font-weight: bold;
    padding: 51px 10px 28px 10px;
    letter-spacing: 1.2px;
    border-radius: 9px;
    font-size: 18px;
}
input[id="et_pb_contact_brand_file_request_0"] {
	display: none;
}
label[for="et_pb_contact_brand_file_request_0"] {
	background: #F5F5F5;
}
.position {
    position: relative;
}
.upload_icon {
    position: absolute;
    top: 20px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 30px;
    text-align: center;
}
label.form-check-label.font-italic {
    font-size: 14px;
    font-weight: 600;
}

label[for="et_pb_contact_brand_file_request_0"]:after {
    display: block;
    content: 'Click here to scan/Upload';
    font-size: 14px;
    color: black;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.notes {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #3E3D3D;
}
.form-check-input:checked {
    background: #33B0CE;
    border: 1px solid #33B0CE;
    border-radius: 3px;
}
.font-italic{
    font-style: italic;
}
.optional {
    font-style: italic;
}

.custom_label {
        width: 50%;
        border: 1px solid #c1c1c1;
        padding: 8px;
        border-radius: 6px;
        margin-right: 11px;
    }
@media (max-width:768px){
    .flex-wrapper{
        display:block;
    }
}




.my_patients h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #101828;
}


/* .form-img-btn{
    align-items: center !important;
    display: flex !important;
} */

@media (max-width:992px){
    .new-patient-page{
        padding: 10px 10px 10px 10px!important;
        }
    
}