

.page-inner-content{
    background:#FFFFFF;
    border-radius:24px;
    position:relative;
}

.page-error-div{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:999999;
     display: flex;
     align-items: center;
     background-color: hsl(215deg 41% 89%);
     transition: background-color 300ms;
    justify-content: center;
    

}
.page-error-after{
    position:absolute;
}


.img-holder{
    width:500px;

}


.img-holder img {
    width:100%;
    height:100%;
}

.page-error-content{
    display:flex;
    align-items:center;
    justify-content:center;
    padding-bottom: 32px;
}

.page-error-btn{
    justify-content: center;
    margin: 0 auto;
    display: flex;
}

.btn-link{
    text-decoration: none !important;
    background:#33B0CE;
    min-height: 50px;
    line-height: 3;
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 14px;
    color:#FFFFFF;
}

.btn-link:hover{
    color:#FFFFFF;
}

.page-inner-content::after {
    content: '';
    position: absolute;
    background: url(../img/page-error-bg.png);
    width: 13.5%;
    top: 0;
    height: 126px;
    right: 49px;
}

.page-error-after::before {
    content: '';
    position: absolute;
    background: url(../img/page-bg.png);
    width: 13.5%;
    /* top: 0; */
    height: 126px;
    right: -454px;
    bottom: -212px;
}