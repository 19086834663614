
p {
    margin: 0px;
}


.document-page_content{
    padding-top:58px !important;
}


.main_heading h4 {
    font-size: 20px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 10px;
}
.top_heading{
    margin-bottom: 20px;
}
.report_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.Document_text h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #101828;
}
.searchicon_main {
    position: relative;
}
::placeholder {
    color: #969696 !important;
}
.searchicon_main input {
    padding: 9px 16px;
    border-radius: 4px;
    padding-left: 40px;
    font-size: 13px;
    color: #969696;
}
.searchicon_main i {
    position: absolute;
    top: 9px;
    left: 15px;
}
.Surgery-Scheduled {
    font-size: 12px;
    width: 165px;
    margin-top: 9px;
    padding: 6px 11px;
}

.recent_document_text{
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: #101828;
}
.view_all_btn {
    text-align: end;
}
.view_all_btn a{
    text-decoration: none;
    color: #33B0CE;
    text-align: end;
    font-weight: 700;
    font-size: 15px;
    line-height: 36px;

}
.top_section-border {
    border-bottom: unset !important;
}
.tab-My_table .table_heading {
    background: transparent;
}
.My_table thead tr th {
    min-width: 150px;
}
.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0.25rem;
    color: #000;
}

button#nav-profile-tab {
    background: #F8F8F8;
    border-radius: 5px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    color: #BFCACF;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: #33B0CE !important;
    color: #ffff !important;
}


.document_slip {
    font-size: 20px 30px;
    color: #8099A6;
    background: #ffff;
    padding: 13px 20px;
    border-bottom-left-radius: 7.6569px;
    border-bottom-right-radius: 7.6569px;
}
.slip_img {
    width: 289px;
    margin: 0 auto!important;
}
.slip_img img{
    width: 100%;
}
.slip_date {
    position: absolute;
    top: 6px;
    color: #ffff;
    right: 6px;
    padding: 5px;
    background: #FE5050;
    border-radius: 3px;
}
.slip_date h6{
    font-size: 10px;
    margin: 0;
    
}
.color_grey {
    color: #8099A6;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 2px;
}
.color_black {
    color: #8099A6;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 6px;
}

.Recents_Documents_slip {
    overflow: hidden;
    background: #F6F6F6;
    box-shadow: 0px 3.06276px 12.251px rgb(0 0 0 / 6%);
    border-radius: 7.6569px;
    position: relative;
}
.mv__Recents_Documents_slip .slip_img,
.mv__Recents_Documents_slip .slip_img .img-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.mv__Recents_Documents_slip .slip_img .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.attached_button {
    color: #33B0CE;
    border: 0.5px solid #33B0CE;
    border-radius: 3px;
    background: #ffff;
    font-size: 9px;
    padding: 2px 15px;
    margin-top: 5px;
}
.Recents_Documents_slip a:after {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
    content: '\f06e';
    width: 50px;
    height: 50px;
    font-family: FontAwesome;
    display: inline-block;
    font-size: 20px;
    /* border: 1px solid #ffffff; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    line-height: 50px;
    z-index: 9;
    background: #ffffff;
    color: #33B0CE;
    text-align: center;
    border-radius: 50px;
}
.Recents_Documents_slip a:before {
    opacity: 1;
    content: '';
    width: 100%;
    height: 100%;
    display: inline-block;
    font-family: FontAwesome;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 25px;
    line-height: 25px;
    z-index: 1;
}
.Recents_Documents_slip a:hover:after {
    opacity: 1;
}
.gallery a:hover:before {
    background-color: rgba(51, 176, 206, 0.49);
}
.gallery a:after, .gallery a:hover:after {
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    -moz-transition: all 1s;
}


.report_img_td {
    width: 100px;
}

table.table.My_table {
    color: #455A64;
}

tr.table_heading th {
    border: 0px !important;
}

table thead tr.table_heading {
    border-radius: 6px;
    border: 0px !important;
    color: #8495B1;
}

.report_img {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    border: 2px solid #8099A6;
    border-radius: 9px;
    overflow: hidden;
}
.report_img img{
    width: 100%;
}
tr.table_heading th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
tr.table_heading th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.table_inner{
    display: flex;
}
.table_icon {
    padding-left: 10px;
}
.pagination_wrapper{
    padding-top: 50px;
}
.pagination_card{
    justify-content: end;
}
.pagination_card li a {
    color: #8099A6;
}
.page-item.active .page-link {
    z-index: 3;
    color: #33B0CE;
    background-color: #ffffff;
    border-color: #dfe2e6;
}
a.page-link.link_next {
    color: #33B0CE;
}


/* new css */
a.page-link.active_button {
    background: #57c7da !important;
    /* color: #FFFFFF !important; */
}

a.page-link:focus {
    outline: none !important;
    box-shadow: none;
}

@media (max-width:991px){
    .Page_content {
        margin-left: 0px;
        padding: 40px;
    }

    .Journey_dropdown {
        margin-left: 9px;
        padding-right: 34px;
    }
    .searchicon_main input {
        margin-left: 0px;
    }
    .Procedure_dropdown {
        margin-left: 0px;
    }
    .searchicon_main i {
        left: 14px;
    }
    .submit_btn {
        text-align:center;
    }
    .submit_button {
        margin-left: 0px;       
    }

}
@media (max-width:768px){
    .sidebar_section{
        display: none;
    }
    .Page_content {
        margin-left: 0px;
        padding: 40px;
    }
    .searchicon_main i {
        left: 15px;
    }
    .searchicon_main input {
        margin-left: 0px;
    }

    .Procedure_dropdown {
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 0px;
    }
    .float-end {
        float: left!important;
    }
    .Journey_dropdown {
        margin-bottom: 10px;
        margin-left: 0px;
    }
    .submit_button{
        margin-left: 0px;
    }
    .Recents_Documents_slip {
        margin-bottom: 15px;
    }
    .slip_img {
        width: 100%;
    }

}
@media (max-width:576px){
    .Page_content {
        margin-left: 0px;
        padding: 20px;
    }

}




@media (max-width: 1350px){  
    .top_section .form-box:first-child {
        width: 100%;
    }
 
}


@media (max-width: 1024px){
.sidebar_section {
display: block;
width: 70px;
}

.sidebar_section .xl-logo {
display: none;
}
.sidebar_section .sm-logo {
display: block !important;
}
.sidebar_section .logo_card {
width: 55px;
}
.Dr_name {
display: none;
}
.sider-barSpacing {
padding: 10px 10px;
}
.monson-navmenu li.nav-item a {
text-align: center;
}
.bottom_side {
    padding: 5px 20px 5px 5px;
}
.Page_content {
margin-left: 70px;
}
.bottom_side {
    left: 0px;
    max-width: 68px;
}
.logout_icon {
    margin-top: 10px;
}
.sidebar_section .btn {
    border-radius: 8px;
    font-size: 9px;
}
.sidebar_section {
    padding-bottom: 100px;
}
.bottom_inner {
    flex-direction: column;
}
.bottom_side {
    padding: 5px 10px 8px;
}
.monson-navmenu li.nav-item a {
    flex-direction: column;
    font-size: 10px;
}
span.link_names {
    margin-left: 0;
    display: inline-block;
}
.Page_content {
    padding: 20px 20px;
}

/* .referralschatList {
    min-width: 80px;
    max-width: 80px;
} */
.chatList-inner {
    padding: 40px 5px 20px 5px;
    text-align: center;
}
.chatUser-allList li a {
    padding: 10px!important;
    /* flex-direction: column; */
}
.chatUser-name p {
    font-size: 11px;
}
.chatUser-name {
    width: calc(100% - 60px);
    text-align: left;
}
.chatUser-name h2 {
    font-size: 12px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.chat-searcIcon input {
    border-radius: 200px;
}
.chatList-inner h2 {
    font-size: 14px;
}
.searchicon.chat-searcIcon i {
    left: 50%;
    transform: translateX(-50%);
}
ul#pills-tab {
    gap: 12px;
}

.chatList-inner h2 {
    font-size: 18px;
    padding: 12px 12px 0px 12px;
}
}

@media (max-width:992px){
.Page_content.customContainerWrapper {
    padding-right: 20px;
}
.dj p {
    font-size: 16px;
}
.content_inner p {
    font-size: 16px;
}
.chatUser-allList li a{
    flex-direction: column;
}
.chatUser-name{
    text-align: center;
}
}


@media (max-width:768px){
/* .top_button {
    margin-top: 21px;
} */
.Aidan_wrapper {
    padding: 0px 20px 20px 20px;
}
.Contacted_inner {
    gap: 10px;
    flex-wrap: wrap;
}
.message_file_comon_icon .nav-link {
    padding-left: 15px;
    padding-right: 0px;
}
.activity_card {
    margin-left: 30px;
}
}


@media (max-width:575px){
    .main_heading h4 {
        font-size: 22px;
    }
    .top_button ul li {
        flex-grow: 1;
    }
    
   
    
    .top_button ul li button.nav-link {
        width: 100%;
    }
    .Aidan_wrapper {
        padding: 0px 0px 20px 10px;
    }
    .Radio_button {
        gap: 10px;
        flex-wrap: wrap;
    }
    .Contacted_card {
        padding-right: 0;
    }
    .activity_card {
        margin-left: 24px;
    }
    .journlistpopup {
        max-width: 290px;
    }
    .My_table thead tr th {
        min-width: 90px;
    }
}


@media (max-width:480px){
.message_file_comon_icon .nav-link {
    padding-left: 0;
    padding-right: 15px;
}
.top_section .col {
    flex: 0 1 100%;
}
}
    



@media (max-width:991px){
    .Page_content {
        margin-left: 0px;
        padding: 40px;
    }
    .Total_Patients {
        margin-bottom: 15px;
    }
    .searchicon_main input {
        margin-left: 0px;
    }
    .Procedure_dropdown {
        margin-left: 0px;
    }
    .searchicon_main i {
        left: 14px;
    }
    .submit_btn {
        text-align:center;
    }
    .submit_button {
        margin-left: 0px;       
    }


}
@media (max-width:768px){
    .sidebar_section{
        display: none;
    }
    .Page_content {
        margin-left: 0px;
        padding: 40px;
    }
    .searchicon_main i {
        left: 15px;
    }
    .searchicon_main input {
        margin-left: 0px;
    }
    .Total_Patients {
        margin-bottom: 15px;
    }
    .submit_button{
        margin-left: 0px;
    }
    .Recents_Documents_slip {
        margin-bottom: 15px;
    }
    .slip_img {
        width: 100%;
    }

}
@media (max-width:576px){
    .Page_content {
        margin-left: 0px;
        padding: 20px;
    }
    .Total_Patients {
        margin-bottom: 15px;       
    }

}
