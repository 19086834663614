.btn-getReport {
    background: #33B0CE;
    border-radius: 10px;
    padding: 10px 10px;
    color: #ffffff;
    width: 100%;
    max-width: 160px;
}
.btn-getReport:focus, .btn-getReport:hover  {
    background: #33B0CE;
    border-radius: 10px;
    padding: 10px 30px;
    color: #ffffff;
}
.medium-heading {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
}
.monson-form-control {
    border: 1px solid #EAEAEA;
    border-radius: 6px !important;
    height: 45px;
}
.monson-form-control:focus {
    border-color: #33b0ce;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(51 176 206 / 12%);
}
.label-heading {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #3E3D3D;
    margin-top: 18px;
}
.vision-card {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgb(0 0 0 / 6%);
    border-radius: 10px;
    border: 0;
}
.vision-input-group-text {
    position: absolute;
    right: 0px;
    width: 45px;
    height: 45px;
    background: transparent;
    border: 0;
    color: #8495B1;
}
::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }