html,
body {
    overflow-x: hidden;
}

.monson-navmenu li.nav-item.active a {
    padding: 10px;
    border-radius: 10px;
    color: #33b0ce;
    background: transparent;
}

.monson-navmenu li.nav-item:hover a {
    background: #33b0ce;
    padding: 10px;
    border-radius: 10px;
    color: #ffffff;
}

.monson-navmenu li.nav-item a {
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
    color: #3d3d3d;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.navmenu.monson-navmenu .nav-item .nav-link img {
    width: 100%;
    max-width: 24px;
    min-width: 24px;
    filter: contrast(0) brightness(0.3);
}

.navmenu.monson-navmenu .active .nav-link img {
    filter: contrast(1) brightness(1);
}

.navmenu.monson-navmenu li .nav-link:hover img {
    filter: contrast(1) brightness(1);
}

.monson-navmenu li.nav-item a i {
    color: #3d3d3d;
}

.monson-navmenu li.nav-item.active a i {
    color: #33b0ce;
}

.monson-navmenu li.nav-item:hover a i {
    color: #ffffff;
}

.logo_card {
    width: 150px;
}

.sidebar_section {
    width: 300px;
    min-height: 100vh;
    overflow-y: auto;
    padding-bottom: 60px;
}

.sidebar_section.active {
    width: 70px;
}

.sidebar_section.active .sider-barSpacing {
    padding: 10px 5px;
}

.monson-navmenu li.nav-item:hover a {
    color: #33b0ce;
    background: transparent;
}

.monson-navmenu li.nav-item:hover a i {
    color: #33b0ce;
}


/* .sidebar_section.active .logo_card {
display: none;
} */

.sidebar_section.active a.nav-link {
    text-align: center;
}

.sidebar_section.active {}

.sidebar_section.active .logo_card {
    width: 55px;
}

.sidebar_section.active .logo_card .xl-logo {
    display: none;
}

.sidebar_section .logo_card .sm-logo {
    display: none;
    width: 55px !important;
}

.sidebar_section.active .logo_card .sm-logo {
    display: block;
}

.logo_card {
    width: 195px;
    padding-bottom: 0px;
}

span.link_names {
    margin-left: 14px;
}

.bottom_inner a {
    list-style: none;
    text-decoration: none;
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 600;
}

.dr_img {
    width: 50px;
    height: 50px;
    border: 1px solid #cccccc;
    border-radius: 50px;
}

.bottom_side {
    padding: 5px 10px 5px 10px;
}

.sidebar_section.active .Dr_name,
.sidebar_section.active .logout_icon {
    display: none;
}

.sidebar_section.active .bottom_side {
    padding: 5px 10px;
}

.referralschatList {
    /* margin-left: 90px; */
    /* width: calc(25% - 70px); */
    border-right: 1px solid #cccccc;
    /* display: none; */
    /* min-width: 260px; */
    background: #FFFFFF;
    position: fixed;
    bottom: 0;
    top: 80px;
    min-height: 100vh;
    height: 100vh;
    overflow-y: auto;
}

.referralschatList::-webkit-scrollbar {
    width: 6px;
}

.referralschatList.active {
    display: block;
    float: left;
}

.chat-searcIcon input {
    border-radius: 6px;
    height: 45px;
}

.searchicon.chat-searcIcon i.fa.fa-search {
    top: 13px;
}

.chatList-inner {
    padding: 10px 20px 15px 20px;
}

.chatList-inner h2 {
    color: #263238;
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 10px;
}

.chatUser-img {
    width: 36px;
    height: 36px;
    overflow: unset;
    border-radius: 50px;
    border: 1px solid #ffffff;
    position: relative;
}

.mv__chatuser_status {
    position: absolute;
    top: 3px;
    left: 0px;
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 100%;
    border: 1px solid #ffffff;
}

.chatUser-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chatUser-name {
    width: calc(100% - 60px);
}

.chatUser-name h2 {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #263238;
    margin-bottom: 5px;
}

.chatUser-name p {
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */
    color: #8495B1;
}

ul.chatUser-allList li a {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    list-style: none;
    padding: 15px;
    border-top: 1px solid rgba(214, 214, 214, 0.4);
    text-decoration: none;
}

ul.chatUser-allList li .active {
    background: #D2EBF1;
}

ul.chatUser-allList li .active .chatUser-name h2 {
    color: #1B8098;
}

ul.chatUser-allList li .active .chatUser-name p {
    color: #1B8098;
}

ul.chatUser-allList li:last-child {
    border-bottom: 1px solid rgba(214, 214, 214, 0.4);
}

.dr_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

table.table.My_table td {
    font-weight: 500;
    font-size: 14px;
    color: #263238;
    padding: 0.8rem;
    white-space: nowrap;
}

.custom-action-td {
    width: 170px;
}

.Page_content.customContainerWrapper {
    padding-right: 90px;
}

.top_section {
    margin: 0 -10px;
}

.top_section .form-box {
    flex-grow: 1;
    padding: 0px 10px 10px;
}

.top_section .form-box:last-child {
    flex-grow: inherit;
}

.border-bottom-ipx {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

.sidebar_section.active .btn {
    font-size: 9px;
}

.sidebar_section.active .bottom_side {
    left: 0px;
    max-width: 69px;
}

.sidebar_section.active .monson-navmenu li.nav-item a {
    flex-direction: column;
    font-size: 10px;
}

.sidebar_section.active .monson-navmenu li.nav-item.active a {
    color: #fff;
    background: #33b0ce;
}

.sidebar_section.active .navmenu.monson-navmenu .active .nav-link img {
    filter: brightness(0) invert(1);
}

.patiend-photo-table-td {
    width: 100px;
}

table.table.My_table .Yesterday_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #8495B1;
    vertical-align: middle;
}

.Page_content.refereal-actity-page {
    padding-top: 0px;
}

.chatUser-allList .nav-item {
    width: 100%;
    position: relative;
}

.mv__chatime {
    font-size: 10px;
    color: #1b8098;
    position: absolute !important;
    right: 4px;
    top: 4px;
}

.Patient_journy_detail .Contacted_card h6 {
    font-weight: 600;
    font-size: 17px;
}

option {
    background-color: white;
    color: grey;
}

select,
input,
button {
    background-color: white;
    border: none;
    padding: 5px;
    border-radius: 4px;
    color: grey;
    cursor: pointer;
    outline-style: none;
    font-size: 16px;
}

select:hover,
input:hover,
button:hover {
    background-color: #EFEFEF;
    color: black;
}

select:not(:last-child),
input:not(:last-child),
button:not(:last-child) {
    margin-right: 4px;
}

select[type=color],
input[type=color],
button[type=color] {
    width: 1.2em;
}

select[type=number],
input[type=number],
button[type=number] {
    width: 3em;
}

#wysiwyg {
    border-radius: 2px;
    width: 100%;
    max-width: 600px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #d0d0d0;
}

#wysiwyg .btns {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #d0d0d0;
    background-color: white;
    flex-grow: 2;
}

#wysiwyg .btns .category {
    display: flex;
    align-items: center;
    border-right: 1px solid #d0d0d0;
    padding: 0px;
}

#wysiwyg #editor {
    resize: none;
    background-color: white;
    outline-style: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px;
    max-height: calc(80vh);
    overflow: auto;
    min-height: 150px;
}

#wysiwyg #editor img {
    max-width: 100%;
}

.Patient_journy_detail {
    flex-direction: column;
}

.journlistpopup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: #fff;
    border-left: 1px solid rgba(150, 150, 150, 0.4);
    box-shadow: 0px 4px 34px rgb(100 106 107 / 11%);
    max-width: 465px;
    min-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.padding-gap {
    padding: 40px;
}

.journlistpopup .nav {
    margin-top: 20px;
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #3E3D3D;
}

.journlistpopup .nav a {
    margin-right: 16px;
}

.journlistpopup .addmorenotes {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FE5050;
    margin-top: 25px;
    display: inline-block;
}

.journlistpopup h6 {
    font-weight: 600;
    font-size: 14px;
    /* line-height: 17px; */
    color: #000000;
    margin-top: 25px;
}

.journey_page_div {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.patient-cards-lists li {
    width: 33.33%;
    padding: 12px;
    position: relative;
}

.patient-cards-lists li img {
    width: 100%;
    height: 100%;
}

.patient-cards-lists h6 {
    float: left !important;
}

.patient-cards-lists {
    margin: 0 -12px;
    padding: 0;
    list-style: none;
}

.patient-cards-lists .close-btn img {
    max-width: 22px;
    position: absolute;
    top: 2px;
    right: 6px;
}

#dropFileForm {
    margin: 4px;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.5s;
}

#dropFileForm #fileLabel {
    display: block;
    padding: 16px;
    position: relative;
    cursor: pointer;
    background: rgba(219, 219, 219, 0.2);
    border-radius: 9.60847px;
    min-height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 35px;
    z-index: 9;
}

#dropFileForm #fileInput {
    display: none;
}

#dropFileForm #fileLabel:after,
#dropFileForm #fileLabel:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: -2;
    border-radius: 8px 8px 0 0;
}

#dropFileForm #fileLabel:before {
    z-index: -1;
    background: #e9fcff;
    opacity: 1;
    transition: 0.5s;
}

#dropFileForm.fileHover #fileLabel:before {
    opacity: 0.25;
}

#dropFileForm .uploadButton {
    border: 0;
    outline: 0;
    width: 100%;
    padding: 8px;
    background-color: limeGreen;
    color: #fff;
    cursor: pointer;
}

#fileLabelText {
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16.8148px;
    line-height: 124.4%;
    color: #263238;
}

.file-upload-btn {
    margin-top: 60px;
}

.activity_card.activetab {
    background: #F8F8F8;
    border-radius: 10px;
}

body .sidebar_section.active span.link_names {
    margin-left: 0;
}

.action-th-width {
    max-width: 30px;
    width: 30px;
    text-align: center;
}

.heading {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #101828;
}

.inner-content {
    display: flex;
}

.dj {
    background: #F17E13;
    border-radius: 50px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    text-align: center;
    margin-top: 18px;
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-blue {
    background: #50BFFE;
}

.background-green {
    background: #8099A6;
}

.background-dark-red {
    background: #CE7A63;
}

.dr-james {
    font-size: 18px;
    width: 100%;
    padding: 20px 0px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
}

.last-seen {
    margin: 0px;
    font-weight: 400;
    font-size: 13.88px;
    line-height: 17px;
    color: #A3A3A3;
    margin-top: 8px;
}

.dr-james h6 {
    margin: 0;
}

.dj p {
    font-size: 25px;
    color: white;
    margin: 0;
}

.highlight-text {
    font-weight: 600;
    font-size: 18.82px;
    line-height: 23px;
    color: #000000;
}

.discription {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(69, 90, 100, 1);
    margin: 8px 0px 0px 0px;
    font-family: 'Inter';
    margin-top: 14px;
}

.Page_content.notification_page_ui .container {
    margin-right: auto;
    margin-left: 0;
    padding-left: 0;
    max-width: 1210px;
}

.top_section .col:first-child {
    flex-grow: 1;
}

.top_section .col {
    flex: 0 1 0%;
}


/*  additional css */


/* .Page_content.refereal-actity-page {
padding-top: 111px;
} */


/* 
.referralschatList {
margin-left: 90px;
width: calc(20% - 70px);
border-right: 1px solid #cccccc;
display: none;
min-width: 260px;
background: #ffffff;
position: fixed;
bottom: 0;
top: 0;
min-height: 100vh;
overflow-y: auto;
} */

@media (max-width: 1350px) {
    .top_section .form-box:first-child {
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .sidebar_section {
        display: block;
        width: 70px;
    }
    .sidebar_section .xl-logo {
        display: none;
    }
    .sidebar_section .sm-logo {
        display: block !important;
    }
    .sidebar_section .logo_card {
        width: 55px;
    }
    .Dr_name {
        display: none;
    }
    .sider-barSpacing {
        padding: 10px 10px;
    }
    .monson-navmenu li.nav-item a {
        text-align: center;
    }
    .bottom_side {
        padding: 5px 10px;
    }
    .Page_content {
        margin-left: 70px;
    }
    .bottom_side {
        left: 0px;
        max-width: 68px;
    }
    .logout_icon {
        margin-top: 10px;
    }
    .sidebar_section .btn {
        border-radius: 8px;
        font-size: 9px;
    }
    .sidebar_section {
        padding-bottom: 100px;
    }
    .bottom_inner {
        flex-direction: column;
    }
    .bottom_side {
        padding: 5px 10px 8px;
    }
    .monson-navmenu li.nav-item a {
        flex-direction: column;
        font-size: 10px;
    }
    span.link_names {
        margin-left: 0;
        display: inline-block;
    }
    .Page_content {
        padding: 20px 20px;
    }
    /* .referralschatList {
min-width: 80px;
max-width: 80px;
} */
    .chatList-inner {
        padding: 40px 5px 20px 5px;
        text-align: center;
    }
    .chatUser-allList li a {
        padding: 10px!important;
        /* flex-direction: column; */
    }
    .chatUser-name p {
        font-size: 11px;
    }
    .chatUser-name {
        width: calc(100% - 0px);
        text-align: center;
    }
    .chatUser-name h2 {
        font-size: 12px;
        margin-bottom: 0;
    }
    .chat-searcIcon input {
        border-radius: 200px;
    }
    .chatList-inner h2 {
        font-size: 14px;
    }
    .searchicon.chat-searcIcon i {
        left: 50%;
        transform: translateX(-50%);
    }
    ul#pills-tab {
        gap: 12px;
    }
}

@media (max-width:992px) {
    .Page_content.customContainerWrapper {
        padding-right: 20px;
    }
    .dj p {
        font-size: 16px;
    }
}

@media (max-width:768px) {
    /* .top_button {
margin-top: 21px;
} */
    .Aidan_wrapper {
        padding: 0px 20px 20px 20px;
    }
    .Contacted_inner {
        gap: 10px;
    }
    .message_file_comon_icon .nav-link {
        padding-left: 20px;
        padding-right: 0px;
    }
    .activity_card {
        margin-left: 30px;
    }
}

@media (max-width:575px) {
    .page-top-insights .insight-box {
        max-width: 100%;
    }
    .main_heading h4 {
        font-size: 22px;
    }
    .top_button ul li {
        flex-grow: 1;
    }
    .top_button ul li button.nav-link {
        width: 100%;
    }
    .Aidan_wrapper {
        padding: 0px 0px 20px 10px;
    }
    .Radio_button {
        gap: 10px;
        flex-wrap: wrap;
    }
    .Contacted_card {
        padding-right: 0;
    }
    .activity_card {
        margin-left: 24px;
    }
    .journlistpopup {
        max-width: 290px;
    }
    .My_table thead tr th {
        min-width: 90px;
    }
    .mv__activity_wrapper {
        padding: 10px 10px 10px !important;
    }
}

@media (max-width:480px) {
    .message_file_comon_icon .nav-link {
        padding-left: 0;
        padding-right: 32px;
    }
    .top_section .col {
        flex: 0 1 100%;
    }
}


/* new css */

.chatList-inner {
    position: sticky;
    top: 0;
    background: #FFFFFF;
    z-index: 9;
}


/* .chatUser-allList .nav-item {
width: 25%;
} */

ul.chatUser-allList li .chatUser-name p,
.chatUser-name h2 {
    margin-bottom: 1px;
}

ul.chatUser-allList li .chatUser-name p,
.chatUser-name h2 {
    margin-bottom: 1px;
    word-break: break-all;
}

ul.chatUser-allList {
    padding: 0px;
    margin: 0px;
    padding-bottom: 90px;
}