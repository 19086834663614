*{
    padding:0px;
    margin: 0px;
    font-family: 'Inter', serif;
}
p {
    margin: 0px;
}
/* .content_wrapper {
    width: 40%;
    margin: 0 auto;
} */
.content_inner h4 {
    font-weight: 600;
    font-size: 28px;
    line-height: 41px;
    text-align: center;
    color: #101828;
    margin-top: 20px;
}
.content_inner p {
    font-weight: 400;
    font-size: 20.6647px;
    line-height: 31px;
    text-align: center;
    color: #475467;
    margin-top: 10px;
}
.back_button {
    border: 0;
    background: transparent;
    font-weight: 600;
    font-size: 18.0816px;
    line-height: 26px;
    color: #475467;
}
.back-btn{
    margin-top: 20px;
}
@media (max-width:991px){
    .content_inner h4{
        font-weight: 600;
        font-size: 22px;
        line-height: 41px;
        text-align: center;
        color: #101828;
    }
}
