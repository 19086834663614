* {
    padding: 0px;
    margin: 0px;
    font-family: 'Inter', serif;
}

p {
    margin: 0px;
}

.sidebar_section {
    float: left;
    position: relative;
    width: 300px;
    border-right: 1px solid rgba(0, 0, 0, 0.13);
    min-height: 100vh;
    background: #fff;
    position: fixed;
    top: 0;
    overflow: hidden;
    bottom: 0;
    left: 0;
    z-index: 999;
}

.sider-barSpacing {
    padding: 20px;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ebebeb;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255);
}

.Refer_button:hover,
.Refer_button:focus {
    background: #2195b1;
    box-shadow: 0px 3.09598px 26.3158px rgb(51 176 206 / 23%);
}

.Existing_Patient_button:hover,
.Existing_Patient_button:focus {
    background: #cf4444;
    box-shadow: 0px 3.09598px 26.3158px rgb(254 80 80 / 23%);
}

.dashboard--box {
    padding: 30px 20px !important;
}

.logo_card {
    width: 175px;
}

.logo_card img {
    width: 100%;
    max-width: 178px;
}

.searchicon {
    position: relative;
    margin-top: 19px;
}

.searchicon input {
    padding: 7px 16px;
    border-radius: 50px;
    padding-left: 40px;
    font-size: 13px;
}

.searchicon i {
    position: absolute;
    top: 9px;
    left: 15px;
}

.navmenu {
    margin-top: 30px;
    margin-bottom: 15px;
}

.navmenu li a {
    color: rgba(38, 50, 56, 1);
}

.btn_card {
    padding-bottom: 30px;
}

.Refer_button {
    width: 100%;
    border: 0;
    padding: 12px 0px;
    box-shadow: 0px 3.09598px 26.3158px rgba(51, 176, 206, 0.23);
    margin-top: 21px;
    background: rgba(51, 176, 206, 1);
}

.btn {
    border-radius: 8px;
    font-size: 18px;
}

.Existing_Patient_button {
    width: 100%;
    border: 0;
    box-shadow: 0px 3.09598px 26.3158px rgb(254 80 80 / 23%);
    padding: 12px 0px;
    margin-top: 21px;
    background: #FE5050;

}

.bottom_side {
    position: fixed;
    bottom: 0px;
    background: #ffffff;
    border-top: 1px solid #D6D6D6;
    left: 15px;
    right: 0;
    padding: 0px 20px;
    max-width: 268px;
}

.bottom_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Dr_name {
    padding-left: 10px;
}

.dr_img {
    border-radius: 100px;
    overflow: hidden;
}

.dr_img_dr_name {
    display: flex;
    align-items: center;
}

.dr_img_dr_name {
    display: flex;
    align-items: center;
}

.logout_icon {
    text-align: end;
}



.Page_content {
    background: #fff;
    margin-left: 380px;
    min-height: 100vh;
    padding: 40px;
}

.main_heading h6 {
    font-size: 25px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 30px;
}

.Total_Patients {
    background: #FFF2DE;
    border-radius: 14px;
    color: #263238;
    padding: 20px;
    height: 100%;
}

.Total_document {
    background: #FFF2DE;
    border-radius: 14px;
    color: #263238;
    padding: 20px;
    height: 100%;
}

.text_342 {
    font-size: 30px;
    font-weight: 700;
    color: #C87F7F;
}

.total_patients_icon {
    text-align: end;
}

.Total_document {
    background: #FFEBEB;
    border-radius: 14px;
    padding: 15px;
    color: #263238;
    height: 100%;
}

.text_232 {
    color: #DCA95E;
    font-size: 30px;
    font-weight: 700;
}

.total_document_icon {
    text-align: end;
}

.Recent_Referrals_card {
    padding: 10px;
    border-radius: 10.1367px;
}

.recent-Patients-text {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: #455A64;
    margin: 0;
}

.table-box {
    background: #FFFFFF;
    border: 1.01367px solid rgba(0, 0, 0, 0.09);
    border-radius: 10.1367px;
    padding: 15px;
    height: 100%;
}

.search-icon {
    position: relative;
    padding-top: 10px;

}

.search-icon input {
    padding: 9px 16px;
    border-radius: 50px;
    padding-left: 40px;
    font-size: 14px;
}

.search-icon .magnifier-icon {
    position: absolute;
    top: 21px;
    left: 28px;
}

table.table.My-table tr td,
table.table.My-table tr th {
    padding: 0.85rem 0.8rem;
}

.recent-Patients-text {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #455A64;
}

.report_image img {
    width: 100%;
    object-fit: cover;
    border-bottom: 1px solid #DBDBDB;
}

.report_image {
    margin: 0px;
    border-radius: 11px;
    overflow: hidden;
    border: 1px solid #DBDBDB;
    width: 100%;
}


.report-spacing-img {
    padding: 12px;
}

.report-inner {
    padding: 6px 10px;
    line-height: 1.3;
}

.last-update {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #8099A6;
    margin-bottom: 5px !important;
}

.aidan-text {
    font-weight: 600;
    margin: 0;
    color: #263238;
}

.aidan-text p {
    margin: 0 !important;
}

.Procedure-text {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #8099A6;
}

.Cataract-text {
    font-size: 12px;
}

.view-all-btn {
    text-align: end;
}

.view-all-btn a {
    text-decoration: none;
    text-align: end;
    font-weight: 600;
    font-size: 12px;
    line-height: 36px;
    text-decoration-line: underline;
    color: #3E3D3D;
    margin-right: 12px;
}

/* .report-card{
    display: flex;
    gap:22px;

} */

.table {
    line-height: 3.1;
    margin-bottom: 0;
    margin-top: 15px;
    font-weight: 500;
    font-size: 13px;
}

.My-table thead tr th {
    min-width: 150px;
}

tr.table-heading-text th {
    border: 0px !important;
}

table thead tr.table-heading-text {
    border-radius: 12px;
    border: 0px !important;
    background: #FAFBFC;
    color: #8495B1;
}

tr.table-heading-text th:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

tr.table-heading-text th:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.patient_slip {
    margin-top: 24px;
}

.Aidan_Nathan {
    color: #263238;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 2px;
}

.procedure-p {
    margin-bottom: 0 !important;
}

.mv__recent-Patients-text {
    color: #455A64;
    font-size: 20px;
}

.mv_thead tr th {
    border: 0;
    background: #FAFBFC;
    color: #8495B1;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    padding: 20px 10px !important;

}

.mv_thead tr th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.mv_thead tr th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.mv_tbody {
    border-top: 0px solid transparent;
}

.mv_tbody tr td {
    font-size: 14px;
    line-height: normal;
    padding: 20px 10px !important;
    font-weight: 400;
}

@media (max-width:991px) {

    .marqueeDiv {
        margin-top: 57px !important;
        margin-bottom: 10px !important;
    }

    .Page_content {
        margin-left: 0px;
        padding: 40px;
    }

    .Total_Patients {
        margin-bottom: 15px;
        /* height:auto; */
    }

    /* .report-card{
        display:block;
    } */

}

@media (max-width:768px) {
    .sidebar_section {
        display: none;
    }

    tr.table_heading th {
        padding-left: 10px;
    }

    /* .report-card{
        display:block;
    } */
    .slip_img {
        width: 100%;
        margin: 0 auto;
    }

    tr.table_data td {
        padding-left: 10px;
    }

    .Page_content {
        margin-left: 0px;
        padding: 40px;
    }

    .Total_Patients {
        margin-bottom: 15px;
    }

    .mv__navbar_toggler_icon {
        width: 22px !important;
    }

    .page-top-insights .insight-box {
        max-width: 100% !important;
        padding-top: 0px !important;
    }

}

@media (max-width:576px) {
    .Page_content {
        margin-left: 0px;
        padding: 20px;
    }

    .Total_Patients {
        margin-bottom: 15px;
    }

    .Total_ODS {
        margin-bottom: 15px;
    }


}

/*new csss */


.table>:not(:last-child)>:last-child> {
    border-bottom-color: none !important;
}



.report_img_holder {
    width: 242px;
}


.table-heading th {
    width: 50px;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: none !important;
    border-bottom: 1px solid transparent !important;
}

/* .th-border{
    border-bottom: 3px solid #f7f7f7 !important;
} */

.tbody-border {
    border-top: 4px solid transparent !important;
}

.mv_tbody tr:last-child td {
    border-bottom: 0px solid transparent !important;
}

.marqueeDiv {
    background-color: #57c7da1c;
    box-shadow: 4px 3px #f8fdfe;
    padding: 0px;
    padding-top: 6px;
    border: 1px solid #37bbd2;
    border-left-color: white;
    border-right: white;
    margin-bottom: 12px;
}

.customBtn {
    width: 100%;
    background-color: #33B0CE;
    font-size: 18px;
    color: white;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 5px 10px 20px 3px #9b9b9b54;
}

tr.hover_tr:hover {
    background-color: #57c7da69;
}
tr.hover_tr {
    border-top: 1px solid #c1c1c1;
    cursor: pointer;
}